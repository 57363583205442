import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
    },
    reducers: {
        setproducts: (state, action) => {
            action.payload.products.forEach((product) => {
                state.products.push(product);
            })
        },

        addProduct: (state, action) => {
            state.products.push(action.payload.product);
        },

        updateProduct: (state, action) => {
            const index = state.products.findIndex((product) => product._id === action.payload.product._id);
            state.products[index] = action.payload.product;
        },

        deleteProduct: (state, action) => {
            const index = state.products.findIndex((product) => product._id === action.payload.product._id);
            state.products.splice(index, 1);
    }
    },
});

export const setproducts1 = productsSlice.actions.setproducts;
export const addProduct1 = productsSlice.actions.addProduct;
export const updateProduct1 = productsSlice.actions.updateProduct;
export const deleteProduct1 = productsSlice.actions.deleteProduct;
export default productsSlice.reducer; 