import React, { useEffect, CSSProperties } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setweb1 } from '../redux/store/web';
import { useNavigate, useParams } from 'react-router-dom';
import { setproducts1 } from '../redux/store/products';
import { PropagateLoader } from "react-spinners";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { setNewOrder1 } from '../redux/store/order';

function GetWebData(props) {
    const order = useSelector((state) => state.setOrder.order);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { web: name } = useParams()
    const arr = [1, 2]
    const getWebByName = async () => {
        const url = window.location.href;
        const matches = url.match(/(?:https?:\/\/)?(?:www\.)?([^./]+)(?:\/[^/]+)?/i);
        const domainName = matches && matches[1];

        const webUrl = `https://ryc-private-domain-server.emzsne.easypanel.host/web/name/${domainName}`;
        const { data: web } = await axios.get(webUrl);
        if (!web) {
            navigate("/404");
        }
        if (web.status === false) {
            navigate("/404");
        }
        const productUrl = `https://ryc-private-domain-server.emzsne.easypanel.host/product/web/${web._id}`;
        const { data: product } = await axios.get(productUrl);
        const filteredProduct = product.filter(product => product.amount === -1 || product.amount > 0);
        dispatch(setweb1({ web: web }))
        dispatch(setproducts1({ products: filteredProduct }))
        const webOrder = order.filter(item => item.webID === web._id)
        checkOrderPrice(webOrder, filteredProduct)
    }


    const checkOrderPrice = (webOrder, products) => {
        webOrder.map(item => {
            const product = products.find(product => product._id === item._id)
            if (product.price !== item.price) {
                dispatch(setNewOrder1({ item1: product }))
            }
        })

    }




    useEffect(() => {
        getWebByName(props);
    }, [])


    return (
        <div style={styles.container}>
            <div style={styles.navbar1}>
                <div style={styles.movingGradient}></div>
            </div>



            <div style={styles.content}>
                {/* Your component content goes here */}
                <h1><Skeleton /> </h1>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", marginTop: "20px" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>
                            </div>
                        )
                    })}

                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>

                            </div>
                        )
                    })}

                </div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>

                            </div>
                        )
                    })}

                </div>

            </div>




            <div style={styles.footer}>
                {/* Footer content goes here */}
            </div>
        </div>
    );
}
const styles = {
    container: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    navbar1: {
        height: '70px',
        position: 'relative',
        overflow: 'hidden',
    },
    movingGradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',
    },
    '@keyframes moveGradient': {
        from: { backgroundPosition: '0% 50%' },
        to: { backgroundPosition: '100% 50%' },
    },
    content: {
        width: '50%',
        margin: '0 auto',  // Center the content horizontally
        marginTop: '30px',

    },
    footer: {
        height: '170px',
        position: 'relative',
        overflow: 'hidden',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',
        marginTop: '0%',
    },

    card: {
        marginTop: '50px',
        width: '200px',
        height: '200px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '0 auto',
        marginTop: '50px',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',

    },
};
export default GetWebData;