import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../css/product.css';
import { setorder1 } from '../redux/store/order';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ProductSelectCard from './comp/ProductSelectCard';

function Product(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { category } = useParams();
    const web = useSelector((state) => state.setWeb.web.web);
    const { product: productName } = useParams();
    const products = useSelector((state) => state.setProducts.products);

    const [product, setProduct] = useState({name:"",enName:"",mainImage:"",color:[{amount:0,color:"",image:""}],size:[{amount:0,size:""}],allImages:[],sameProduct:[],longDescription:""});
    const [mainImage, setmainImage] = useState(product.mainImage);
    const [selectedColor, setSelectedColor] = useState("רגיל");
    const [selectedSize, setSelectedSize] = useState(product.size[0].size);
    const colorMulti = product.color.reduce((acc, color) => acc + color.amount, 0);
    // const sameProduct = products.filter((product) => product.category.includes(category));
    const sameProduct = products.filter((product1) => product.sameProduct.includes(product1.name));

   const getData = ()=>{
    const product = products.find(product => product.name.replace(/\s/g, "-") === productName);
    if (product) {
        setProduct(product);
        setmainImage(product.mainImage)
    } else {
        navigate('/404');
    }

}
    useEffect(() => {
    getData()
    }, [productName]);


    const [params, setParams] = useSearchParams({
        color: "",
        selectedColor: "",
    });
    const [params1, setParams1] = useSearchParams({
        size: "",
    });

    const size = params1.get("size");
    const mainImage1 = params.get("color");
    const selectedColor1 = params.get("selectedColor");

    const setSize = (size) => {
        setSelectedSize(size)
        setParams1((prev) => {
            prev.set('size', size);
            return prev;
        }, { replace: true })
    }

    const setMainImage = (image, color) => {
        setParams((prev) => {
            prev.set('color', image);
            return prev;
        }, { replace: true })
        setmainImage(image);
        setParams((prev) => {
            prev.set('selectedColor', color);
            return prev;
        }, { replace: true })
        setSelectedColor(color)
    }

    const getImages = product.allImages.map(image => {
        return {
            original: `https://ryc-private-domain-image.emzsne.easypanel.host/${image}`,
            thumbnail: `https://ryc-private-domain-image.emzsne.easypanel.host/${image}`,
        }
    })

    const images = [{
        original: `https://ryc-private-domain-image.emzsne.easypanel.host/${mainImage}`,
        thumbnail: `https://ryc-private-domain-image.emzsne.easypanel.host/${mainImage}`,
    }, ...getImages]

    const insertLineBreak = (str, wordsPerLine) => {
        const words = str.split(' ');
        const slicedWords = words.slice(0, wordsPerLine);
        return `${slicedWords.join(' ')}\n${words.slice(wordsPerLine).join(' ')}`;
    };

    const insertLineBreakP = (str, wordsPerLine) => {
        const words = str.split(' ');
        const lines = [];

        for (let i = 0; i < words.length; i += wordsPerLine) {
            const lineWords = words.slice(i, i + wordsPerLine);
            lines.push(lineWords.join(' '));
        }

        return lines.join('\n');
    };

    const addToOrder = () => {

        const random = Math.floor(Math.random() * 10000) + 1022;
        const newProduct = { ...product, selectedColor: selectedColor };
        newProduct["count"] = 1;
        newProduct["id"] = random;
        dispatch(setorder1({ order: newProduct }))

    }
    
    

    useEffect(() => {
        if (mainImage1) {
            setmainImage(mainImage1);
            setSelectedColor(selectedColor1)

        }

        if (size) {
            setSelectedSize(size)
        }

        if(selectedColor === undefined){
            setMainImage(product.mainImage, "רגיל")         
        }

    }, [mainImage1])

   
    


    return (
        <div >
        <div className='container-product'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${web.name}-${product.name}`}</title>
                <link rel="icon" href={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} />
            </Helmet>

            {product.allImages.length > 0 ?
                <div className='imageGallery'>
                    <div className='gallery'>

                        <ImageGallery
                            items={images}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            slideInterval={1000}
                            slideOnThumbnailOver={false}
                            showNav={false}
                            showBullets={false}
                            isRTL={true}
                            thumbnailPosition={window.innerWidth > 900 ? "left" : "bottom"}
                        />
                    </div>
                    <h4>

                        {product.color.length > 0 ?
                            <>
                                <div className='color-buttons'>
                                    {colorMulti > 0 ? <button onClick={() => setMainImage(product.mainImage, "רגיל")} className={`color-product ${selectedColor === "רגיל" ? 'selected' : ''}`}>רגיל</button> : null}
                                    {product.color.map((color, index) => (
                                        color.amount > 0 ? <button onClick={() => setMainImage(color.image, color.color)} className={`color-product ${selectedColor === color.color ? 'selected' : ''}`} key={index}>{color.color}</button> : null

                                    ))}
                                </div>
                            </>
                            : null}
                        {product.size.length > 0 ?
                            <>
                                <div className='color-buttons-size'>
                                    {product.size.map((size, index) => (
                                        size.amount > 0 ? <button className={`color-product ${selectedSize === size.size ? 'selected' : ''}`} onClick={() => setSize(size.size)} key={index}>{size.size}</button>
                                            : null

                                    ))}
                                </div>
                            </>
                            : null}
                    </h4>
                </div> :
                <div>
                    <img className='product-image' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${mainImage}`} alt={product.name} />
                    <h4>
                        {product.color.length > 0 ?
                            <div className='color-buttons'>
                                {colorMulti > 0 ? <button onClick={() => setMainImage(product.mainImage, "רגיל")} className={`color-product ${selectedColor === "רגיל" ? 'selected' : ''}`}>רגיל</button> : null}
                                {product.color.map((color, index) => (
                                    color.amount > 0 ? <button onClick={() => setMainImage(color.image, color.color)} className={`color-product ${selectedColor === color.color ? 'selected' : ''}`} key={index}>{color.color}</button> : null

                                ))}
                            </div>
                            : null}
                        {product.size.length > 0 ?
                            <>
                                <div className='color-buttons-size'>
                                    {product.size.map((size, index) => (
                                        size.amount > 0 ? <button className={`color-product ${selectedSize === size.size ? 'selected' : ''}`} onClick={() => setSize(size.size)} key={index}>{size.size}</button>
                                            : null

                                    ))}
                                </div>
                            </>
                            : null}
                    </h4>
                </div>

            }
            <div style={{backgroundColor:web.color}} className='product-data'>
                <h1 className='h1-product'>{insertLineBreak(product.name, 3)}</h1>
                {product.enName ? <h4 className='h4-product'>{product.enName}</h4> : null}
                {product.longDescription ? <p className='p-product'>{insertLineBreakP(product.longDescription, 8)}</p> : null}

                {web.eilat ? <h4 style={{ marginTop: product.longDescription ? null : "180px" }} className='h4-price'>
                    <>
                        {product.onSalePrice > 0 ?
                            <>
                                <del>

                                    <span>₪{product.price} כולל מע״מ </span>
                                    <span style={{ color: "#FFA500" }}>₪{product.eilatPrice}  מחיר אילת </span>
                                </del>
                                <div style={{ marginTop: "8px" }}>
                                    <span>₪{product.onSalePrice} כולל מע״מ </span>
                                    <span style={{ color: "#FFA500" }}>₪{product.onSalePriceEilatPrice}  מחיר אילת </span>
                                </div>

                            </> :
                            <>
                                <span>₪{product.price} כולל מע״מ </span>
                                <span style={{ color: "#FFA500" }}>₪{product.eilatPrice}  מחיר אילת </span>
                            </>
                        }

                    </>
                </h4>
                    :
                    <h4 className='h4-price'>
                        <span style={{ color: "#FFA500" }}>₪{product.price}  כולל מע״מ </span>
                    </h4>}
                <button  onClick={addToOrder} className='h2-add-to-cart'>הוספה לסל</button>

            </div>


            </div>

            <div className="smaeProduct">
                {product.sameProduct.length > 0 ? <h2>מוצרים דומים</h2> : null}

            </div>
            <div className="sameProduct-container">
                {sameProduct.map((product, index) => {
                    return <ProductSelectCard category={category} key={index} product={product} />
                })}

            </div>


        </div>
    );
}

export default Product;