import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaBars } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { Outlet, Link } from 'react-router-dom';
import "../../css/cart.css"
import { setcart1 } from '../../redux/store/cart';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { deleteorder1 } from '../../redux/store/order'
import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { minusOrder1 } from '../../redux/store/order';
import { plusOrder1 } from '../../redux/store/order';
import { useNavigate } from 'react-router-dom';

function CartComp(props) {
  const dispatch = useDispatch()
  const web = useSelector((state) => state.setWeb.web.web);
  const cart = useSelector((state) => state.setCart.cart);
  const order = useSelector((state) => state.setOrder.order);
  const webOrder =order.filter(item=>item.webID===web._id)
  // const totalSum = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePrice : item.price) * item.count), 0);
  const [totalSum, setTotalSum] = useState(0)
  const navigate = useNavigate();
  const goToCart = () => {
    dispatch(setcart1({ cart: "aaa" }))

    navigate(`/cart` )
  }

  useEffect(() => {
    if (!web) return
    const totalSum = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePrice : item.price) * item.count), 0);
    const totalSumEilat = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePriceEilatPrice : item.eilatPrice) * item.count), 0);
    setTotalSum(web.eilat ? totalSumEilat : totalSum)
}, [web, order,webOrder])



  const minusOrder = (item, index) => {

    dispatch(minusOrder1({ index: index, item: item }))
  }

  const plusOrder = (item, index) => {

    dispatch(plusOrder1({ index: index, item: item}))
  }





  const showSidebar = () => {
    dispatch(setcart1({ cart: "aaa" }))
  }

  const deleteOrder = (index) => {

    dispatch(deleteorder1({ index: index }))
  }



  return (
    <div>
      {web ? (
        <>
          <nav className={cart ? 'nav-menu1 aaa' : 'nav-menu1'}>
            <ul className='nav-menu-items1'>
              <li className="navbar-toggle1">
                <Link className='menu-bars1'>
                  <AiOutlineClose onClick={showSidebar} />
                </Link>
              </li>
              {webOrder.length > 0 ? webOrder.map((item, index) => (
                <li key={index} className='nav-text1'>
                  <div className='cart-item-content'>
                    <img className='imgCard' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${item.mainImage}`} alt="" />
                    <div className='cart-item-details'>
                      <h4 style={{ marginBottom: "5px" }}>{item.name}</h4>
                      {item.onSalePrice > 0 ? (
                        <h4>₪{web.eilat? item.onSalePriceEilatPrice * item.count :item.onSalePrice * item.count}</h4>
                      ) : (
                        <h4>₪{web.eilat?item.eilatPrice * item.count :item.price * item.count}</h4>
                      )}
                      <div className='center-icons'>
                        <FaMinusCircle onClick={() => minusOrder(item, index)} size={20} style={{ paddingLeft: "5px" }} />
                        <h4 className='count'>{item.count}</h4>
                        <FaPlusCircle onClick={() => plusOrder(item, index)} size={20} style={{ paddingRight: "5px" }} />
                      </div>
                    </div>
                  </div>
                  <IoIosCloseCircleOutline onClick={() => deleteOrder(item)} size={25} className='closeCard' />
                  <hr className='hrCard' />
                </li>
              )) : (
                <div>
                  <h4 style={{ fontSize: "20px" }} className='nav-text1'>אין מוצרים בסל</h4>
                </div>
              )}
              {webOrder.length > 0 ? <h4 className='total-sum'>סכום ביניים: {totalSum} </h4> : null}
              {webOrder.length > 0 ? <button onClick={goToCart} className='go-to-cart'>לסל הקניות</button> : null}
            </ul>

          </nav>
        </>
      ) : null}
    </div>
  );

}

export default CartComp;