import React, { useState, useEffect } from 'react';
import "../../css/productSelects.css"
import { FaHotjar } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setorder1 } from '../../redux/store/order';
import { useParams, useNavigate } from 'react-router-dom';


function ProductSelectCard(props) {
    const web = useSelector((state) => state.setWeb.web.web);
    const width = window.innerWidth;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { category } = useParams();

    const navigateToProduct = () => {

        const productUrl = `/products/${props.category}/${props.product.name.replace(/\s/g, "-")}`
        navigate(productUrl, { state: { product: props.product } })
    }

    const addToOrder = (event) => {
        event.stopPropagation(); // Stop the click event propagation

        const random = Math.floor(Math.random() * 10000) + 1022;
        const newProduct = { ...props.product };
        newProduct["count"] = 1;
        newProduct["id"] = random;
        dispatch(setorder1({ order: newProduct }))

    }

    return (

        <div className='categoryContainer1' >
            <div onClick={navigateToProduct}  className='categoryItem1'>
                <div className="imageContainer">
                    {props.product.onSalePrice > 0 ?
                        <>
                            <FaHotjar className="hotjarIcon" color="red" size={width < 550 ? 15 : 20} />
                            <span className="iconText">מבצע</span>
                        </>
                        : null
                    }

                    <img className='img1' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${props.product.mainImage}`} alt="" />
                </div>
                <hr className='hrStyle' />
                <h4 className='text'>{props.product.name}</h4>
                <h5 className='price'>
                    {web.eilat ?
                        <>
                            {props.product.onSalePrice > 0 ?
                                <div>

                                    <div style={{ marginTop: "8px" }}>
                                        <span>₪{props.product.onSalePrice}   </span>
                                        <span style={{ color: "#FFA500" }}>₪{props.product.onSalePriceEilatPrice}   אילת </span>
                                    </div>

                                </div> :
                                <>
                                    <span>₪{props.product.price}   </span>
                                    <span style={{ color: "#FFA500" }}>₪{props.product.eilatPrice}   אילת </span>
                                </>
                            }
                        </>
                        : 
                        <>
                            {props.product.onSalePrice > 0 ?
                                <>
                                    <span style={{ color: "red" }}> ₪{props.product.onSalePrice} </span> &nbsp; &nbsp; <del style={{ fontSize: "14px" }}> ₪{props.product.price}</del>
                                </>
                                : `₪${props.product.price}`}
                        </>

                    }

                </h5>


                <button  style={{backgroundColor:web.color}} onClick={addToOrder} className='h1 h44'>הוספה לסל</button>
            </div>
        </div>

    );
}

export default ProductSelectCard;