import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../css/alert.css';
import { IoIosClose } from "react-icons/io";


function Alert({ open, onClose }) {

    const web = useSelector((state) => state.setWeb.web.web);
    const firstTime = sessionStorage.getItem("webAlert")
    const [webAlertFlag, setWebAlertFlag] = useState(false)

    const alertBT = () => {
        window.location.href = web.alert.btLink
        onClose()
    }

    useEffect(() => {
        if (!web.alert.show) return
        if (firstTime === null) {
            sessionStorage.setItem("webAlert", true)
            // setTimeout(() => {
            //     setWebAlertFlag(true)
            // }, 1000);
        }
        if (firstTime === "false") {
            return
        }
        if (firstTime === "true") {
            setWebAlertFlag(true)
        }

    }, [web])

    if (!open) return null
    return (
        <div>
            {web ?
                <div onClick={onClose} className='overlay'>
                    <div onClick={(e) => { e.stopPropagation() }} className="modalContainer">
                        <div className="modalRight">
                            <div className='closeBtn'>

                                <IoIosClose size={30} onClick={onClose} />
                            </div>
                            <div className="content">
                                <h1 style={{color:web.alert.title.color? web.alert.title.color : "black"}} className='alert-h1'>{web.alert.title.text}</h1>
                                <p style={{color:web.alert.p.color? web.alert.p.color : "black"}} className='alert-p'> {web.alert.p.text}</p>
                                {web.alert.bt ?
                                    <button onClick={alertBT} className='alert-bt'>{web.alert.bt}</button>
                                    : null
                                }
                            </div>

                        </div>
                        {web.alert.image ?
                            <img className='img-alert' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.alert.image}`} alt="" />
                            : null
                        }
                    </div>
                </div>
                : null}

        </div>
    );
}

export default Alert;