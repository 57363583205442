import React, { useState } from 'react';
import axios from 'axios';
import imgae from '../images/ryc2.png'
import "./dsds/aaa.css"
import { Helmet } from 'react-helmet';


function MainPage(props) {

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [bussinesName, setBussinesName] = useState('')


  const sendSms = async () => {
    
    try {
      const url = "https://ryc-private-domain-server.emzsne.easypanel.host/order/newCustomer";
      const data = {
        name: name,
        phone: phone,
        bussinesName: bussinesName
      };

      const order = await axios.post(url, data);
      console.log("Order created successfully:", order.data);
    } catch (error) {
      console.log("Error creating order:", error);
      // Optionally log the response error for more details
      console.log("Error response data:", error.response?.data);    }
  };


  return (
    <div className='main-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ryc</title>
        <link rel="icon" href={imgae} />
      </Helmet>
      <nav className='nav-ryc'>
        <div style={{ direction: "ltr" }} className="nav__logo"><a style={{ color: "white" }} href="#">R<span>Y</span>C</a></div>
      </nav>
      <header>

        <div className="section__container header__container">
          <h1 style={{ color: "white" }}>כבר לא סיפור</h1>
          <h2 style={{ color: "white" }} className="section__title">אתר  <span>בקליק</span></h2>
          <p style={{ color: "white" }}>
            כבר לא כזה סיפור לבנות אתר מכירות,
            בלחיצת כפתור תוכלו להוסיף מוצרים לאתר שלכם ולהתחיל למכור באינטרנט,
            כל הפעולות נעשות בקליק אחד ובלי צורך בידע מוקדם,
            עם כלי ניהול קל ונוח ועיצוב יפה ומודרני.
          </p>

        </div>
      </header>
      <section className="about">
        <div className="section__container about__container">
          <div className="about__image">
            <img src="https://img.freepik.com/free-vector/illustration-social-media-concept_53876-37691.jpg?t=st=1711566114~exp=1711569714~hmac=95b99726950ca67bd0d39cb151d73f2716f761c3a922badc29b9c8d633d54bfe&w=2000" alt="about" />
          </div>
          <div className="about__content">
            <h2 style={{ color: "white" }} className="section__title">קצת <span>עלינו </span></h2>
            <p style={{ color: "white" }} className="section__subtitle">Ryc next markating</p>
            <p style={{ textAlign: "right" }} className="about__details">
              החברה שלנו פתחה מערכת ניהול קלה ונוחה לבניית אתרי מכירות,
              כלי ניהול קל ונוח ועיצוב יפה ומודרני.
              כל הפעולות נעשות בקליק אחד ובלי צורך בידע מוקדם,
              <br /> עם מערכת ניהול, ניתן להוסיף מוצרים, לערוך מוצרים. <br />
              הכל בצורה קלה ונוחה. <br />
              נרשמים בקליק ומתחילים למכור.
            </p>
            {/* <button className="btn">More About</button> */}
          </div>
        </div>
      </section>

      <section className="service">
        <div className="section__container service__container">
          <h2 style={{ color: "white" }} className="section__title"> מה<span>מקבלים</span></h2>
          <p style={{ color: "white" }} className="section__subtitle">וזה לא הכל...</p>
          <div className="service__grid">
            <div className="service__card">
              <i className="ri-smartphone-line"></i>
              <h4 style={{ color: "white" }}>אתר מכירות</h4>
              <p>
                &#x2022; מותאם לכל מכשיר <br />
                &#x2022; עיצוב יפה ומודרני <br />
                &#x2022; אתר מונגש <br />
                &#x2022; תקנון ומדיניות פרטיות <br />
                &#x2022; לכם רק נשאר להוסיף מוצרים
              </p>
              {/* <a style={{ color: "white" }} href="#" className="read__more">Read more...</a> */}
            </div>
            <div className="service__card">
              <i className="ri-code-s-slash-line"></i>
              <h4 style={{ color: "white" }}>מערכת ניהול</h4>
              <p>
                &#x2022; ניהול מוצרים <br />
                &#x2022; ניהול הזמנות <br />
                &#x2022; ניהול מלאי <br />
                &#x2022; קבלת מסרון על הזמנות חדשות <br />
                &#x2022; מערכת ניהול קלה ונוחה ללא צורך בידע מוקדם
              </p>
              {/* <a style={{ color: "white" }} href="#" className="read__more">Read more...</a> */}
            </div>
            <div className="service__card">
              <i className="ri-edit-2-line"></i>
              <h4 style={{ color: "white" }}>אחזקת אתר</h4>
              <p>
                &#x2022; תמיכה טכנית <br />
                &#x2022; עדכונים ושדרוגים <br />
                &#x2022; גיבוי ושחזור <br />
                &#x2022; אבטחה והגנה <br />
                &#x2022; שרתים מהירים ומאובטחים

              </p>
              {/* <a style={{ color: "white" }} href="#" className="read__more">Read more...</a> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="project">
        <div className="section__container project__container">
          <p style={{ color: "white" }} className="section__subtitle">Portfolio</p>
          <h2 style={{ color: "white" }} className="section__title"><span>Latest</span> Project</h2>
          <div className="project__grid">
            <div className="project__card">
              <img src="assets/project-1.png" alt="project" />
            </div>
            <div className="project__card">
              <img src="assets/project-2.png" alt="project" />
            </div>
            <div className="project__card">
              <img src="assets/project-3.png" alt="project" />
            </div>
            <div className="project__card">
              <img src="assets/project-4.png" alt="project" />
            </div>
            <div className="project__card">
              <img src="assets/project-5.png" alt="project" />
            </div>
            <div className="project__card">
              <img src="assets/project-6.png" alt="project" />
            </div>
          </div>
        </div>
      </section> */}

      <footer>
        <div className="section__container footer__container">
          <div className="footer__content">
            <h2 style={{ color: "white" }} className="section__title">צרו <span>קשר!</span></h2>
            <p className="section__subtitle" style={{ color: "white" }}>אם אתם מעוניינים לשמוע עוד</p>
            <div className="footer__details">
              <p style={{ color: "white" }}>
                ניתן ליצור איתי קשר דרך טופס יצירת הקשר המופיע
                באתר אינטרנט. כל שעליך לעשות הוא למלא את הטופס עם שמך, כתובת הדוא"ל שלך ,
                ומספר טלפון ושלח הודעה, ואחזור אליך בהקדם האפשרי.
              </p>
              <p>ryc</p>
              <p>israel eialt</p>
            </div>
            {/* <div className="social__icons">
              <a href="#" className="icon"><i className="ri-facebook-fill"></i></a>
              <a href="#" className="icon"><i className="ri-mail-line"></i></a>
              <a href="#" className="icon"><i className="ri-twitter-fill"></i></a>
              <a href="#" className="icon"><i className="ri-linkedin-fill"></i></a>
            </div> */}
          </div>
          <div className="footer__form">
            <input onChange={(e) => setName(e.target.value)} type="text" placeholder="שם מלא" />
            <input onChange={(e) => setPhone(e.target.value)} type="text" placeholder="מספר טלפון" />
            <input onChange={(e) => setBussinesName(e.target.value)} type="text" placeholder="שם העסק " />
            <button onClick={sendSms} className="btn">שלח</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default MainPage;