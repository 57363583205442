import { createSlice } from "@reduxjs/toolkit";

const payflagSlice = createSlice({
    name : "payflag",
    initialState : {
        payflag :false,
    },
    reducers : {
        setpayflag:(state, action)=>{
            state.payflag = action.payload
        },
    }
});

export const setpayflag1 = payflagSlice.actions.setpayflag;
export default payflagSlice.reducer; 