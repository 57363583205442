import React, { useState, useEffect } from 'react';
import { Link, Outlet, useFetcher } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import "../css/NavBar.css"
import { useSelector, useDispatch } from 'react-redux';
import GetWebData from '../function/GetWebData';
import { AiOutlineHome } from "react-icons/ai";
import { IoPeopleOutline } from "react-icons/io5";
import { GrCatalog } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { MdAddShoppingCart } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWaze } from "react-icons/fa";
import CartComp from './comp/CartComp';
import { setcart1 } from '../redux/store/cart';
import Alert from './Alert';


function NavBar(props) {
    const web = useSelector((state) => state.setWeb.web.web);
    const order = useSelector((state) => state.setOrder.order);
    const products = useSelector((state) => state.setProducts.products);

    const [webOrder, setWebOrder] = useState([])
    const cart = useSelector((state) => state.setCart.cart);
    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const total = webOrder.reduce((acc, curr) => acc + (curr.onSalePrice > 0 ? curr.onSalePrice : curr.price), 0);
    const dispatch = useDispatch()
    const orderMulti = webOrder.reduce((acc, curr) => acc + (curr.count), 0);
    const [totalSum, setTotalSum] = useState(0)
    const [webAlertFlag, setWebAlertFlag] = useState(false)
    const showcart = () => {
        dispatch(setcart1({ cart: "aaa" }))
    }
    const firstTime = sessionStorage.getItem("webAlert")

    const navBarData = web ? [

        {
            title: 'דף הבית',
            path: `/`,
            icon: <AiOutlineHome />,
            cName: 'nav-text'
        },
        {
            title: 'המוצרים שלנו',
            path: `/products`,
            icon: <GrCatalog />,
            cName: 'nav-text'
        },
        {
            title: 'אודות',
            path: `/about`,
            icon: <IoPeopleOutline />,
            cName: 'nav-text'
        },
        {
            title: 'צור קשר',
            path: `/contact`,
            icon: <FiPhoneCall />,
            cName: 'nav-text'
        },
        {
            title: ' סל קניות',
            path: `/cart`,
            icon: <MdAddShoppingCart />,
            cName: 'nav-text'
        },
        {
            title: ' תקנון ',
            path: `/terms`,
            icon: <FaFileContract />,
            cName: 'nav-text'
        }
    ] : []

    const handleModal = () => {
        setWebAlertFlag(false)
        sessionStorage.setItem("webAlert", false)

    }

    const aaa = () => {
        if (!web.alert.show) return
        if (firstTime === null) {
            sessionStorage.setItem("webAlert", true)
            setTimeout(() => {
                setWebAlertFlag(true)
            }, 1000);
        }
        if (firstTime === "false") {
            return
        }
        if (firstTime === "true") {
            setWebAlertFlag(true)
        }
    }

   

    useEffect(() => {
        if (!web) return
        const totalSum = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePrice : item.price) * item.count), 0);
        const totalSumEilat = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePriceEilatPrice : item.eilatPrice) * item.count), 0);
        setTotalSum(web.eilat ? totalSumEilat : totalSum)
    }, [web, order,webOrder])

    useEffect(() => {
        if (!web) return
        setWebOrder(order.filter(item => item.webID === web._id))
    }, [order, web])

    useEffect(() => {
        if (!web) return
        if (!web.alert.show) return
        if (firstTime === null) {
            sessionStorage.setItem("webAlert", true)
            setWebAlertFlag(true)

        }
        if (firstTime === "false") {
            return
        }
        if (firstTime === "true") {
            setWebAlertFlag(true)
        }

    }, [web])

    return (
        <div>

            {web ? (
                <>
                    <div style={{ backgroundColor: web.color }} className="navbar">

                        <div style={{ display: "none" }}>
                        </div>
                        <Link to={"#"}>
                            <FaBars style={{ paddingRight: "10px" }} size={45} onClick={showSidebar} />
                        </Link>
                        <Link to={`/`}>
                            <img className='nav-logo' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} alt="" />
                        </Link>
                        <span style={{ display: "flex" }}>
                            <p className='order'>{webOrder.length === 0 ? null : orderMulti} {totalSum > 0 ? `₪` + totalSum : null}  </p>
                            <MdAddShoppingCart onClick={showcart} style={{ paddingLeft: "10px" }} size={40} color='white' />
                        </span>
                    </div>

                    <nav style={{ backgroundColor: web.color }} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <ul className='nav-menu-items' onClick={showSidebar}>
                            <li style={{ backgroundColor: web.color }} className="navbar-toggle">
                                <Link to={"#"} className='menu-bars'>
                                    <AiOutlineClose />
                                </Link>
                            </li>
                            {web.logo ? <li><img style={{ height: "100px", width: "220px", marginBottom: "30px" }} src={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} alt="" /></li> : null}

                            {navBarData.map((item, index) => {
                                return (
                                    <li key={index} className={item.cName}>
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            })}

                            {web.instagram ? <FaInstagram size={25} onClick={() => window.location.href = ` ${web.instagram}`} color='white' style={{ marginRight: web.facebook ? "35px" : "50px", marginLeft: "20px", marginTop: "100%" }} /> : null}
                            {web.facebook ? <FaFacebook size={25} onClick={() => window.location.href = ` ${web.facebook}`} color='white' style={{ marginLeft: "20px", marginRight: web.instagram ? null : "35px", marginTop: web.instagram ? null : "120%" }} /> : null}
                            {web.contact.address ? <FaWaze onClick={() => window.location.href = `httpss://waze.com/ul?q=some%20${web.contact.address}`} size={25} color='white' /> : null}


                        </ul>
                    </nav>
                    {cart ? <CartComp /> : null}
                    <Alert open={webAlertFlag} onClose={handleModal} />

                    <Outlet />
                </>
            ) :
                <GetWebData />

            }

        </div>
    );
}

export default NavBar;