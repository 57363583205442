import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import GetWebData from '../function/GetWebData';
import { Helmet } from 'react-helmet';
import '../css/home.css';
import ProductSelectCard from './comp/ProductSelectCard';

function Home(props) {
  const web = useSelector((state) => state.setWeb.web.web);
  const products = useSelector((state) => state.setProducts.products);
  const onSaleProducts = products.filter((product) => product.onSalePrice > 0);
  const reversedProducts = [...products].reverse().slice(0, 6);


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!web) {
      return;
    }
    const interval = setInterval(() => {
      // Increment the index, and loop back to the beginning if it exceeds the array length
      setCurrentIndex((prevIndex) => (prevIndex + 1) % web.upBunner.length);
    }, 4000);

    // Clear the interval when the component is unmounted or when the array of images changes
    return () => clearInterval(interval);
  }, [currentIndex,web]);



  return (

    <div>



      {web ? (
        <div className='home-container'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{web.name}</title>
            <link rel="icon" href={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} />
          </Helmet>
          {web.upBunner.length > 0 ?
            <img className='home-image' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.upBunner[currentIndex]}`} alt={`Slide ${currentIndex}`} />

            :

            null
          }

          {/* on sale products                               */}
          {onSaleProducts.length > 0 ?
            <>
              <div>
                <h1 className='home-h1'>מבצעים</h1>
                <hr />
              </div>
              <div className='home-product-card'>
                {onSaleProducts.map((product, index) => (
                  product.showOnHome ? (
                    <ProductSelectCard homePage={true} key={index} category={product.category[0]} product={product} />
                  ) : null
                  // <ProductSelectCard homePage={true} key={index} category={product.category[0]} product={product} />
                ))}

              </div>
            </>
            : null
          }
          {/* ------------------------------------------ */}





          {/* new products                        */}
          {reversedProducts.length > 0 ?
            <>
              <div className='home-new-products'>
                <h1 className='home-h1'>מוצרים חדשים</h1>
                <hr />
              </div>
              <div className='home-product-card'>
                {reversedProducts.map((product, index) => (
                  <ProductSelectCard homePage={true} key={index} category={product.category[0]} product={product} />
                ))}
              </div>
            </>
            : null
          }
          {/* ------------------------------------------ */}



          {/* all category                                   */}
          {web.category.length > 0 ?
            <>
              <div className='home-new-products'>
                <h1 className='home-h1'> קטגוריות</h1>
                <hr />
              </div>
              <div className='home-categoryContainer' >

                {web.category.map((category, index) => {
                  return (

                    <div className='home-categoryItem' key={index} >
                      <img className='home-img' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${category.image}`} alt="" />
                      <Link to={`/products/${category.name}`}>
                        <h4 className='home-h' >{category.name}</h4>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </> : null
          }


          {/* ------------------------------------------ */}



          {/* activity Time                               */}

          <>
            <div className='home-ctivity-Time' >
              <div >
                <h1 className='home-h1-ctivity' style={{ marginTop: "10px" }} > שעות פעילות</h1>
                <h1 className='home-h1-ctivity'  >  יצירת קשר</h1>
              </div>
              <div className='home-ctivity-Time-container'>
                <div className='home-p'>
                  <p className='home-p-address'>כתובת:<span style={{ color: "white" }}>{web.contact.address}</span></p>
                  <p className='home-p-address'>מייל:<span style={{ color: "white" }}>{web.contact.email}</span></p>
                  <p className='home-p-address'>טלפון:<span style={{ color: "white" }}>{web.contact.phone}</span></p>
                  <br />
                  <p className='home-p-time'>  ימים א’ עד ד’:<span style={{ color: "white" }}>{web.activityTime.day}</span></p>
                  <p className='home-p-time'>יום ה’:<span style={{ color: "white" }}>{web.activityTime.Thursday}</span></p>
                  <p className='home-p-time'>יום ו’:<span style={{ color: "white" }}>{web.activityTime.Friday}</span></p>
                  <p className='home-p-time'>יום שבת:<span style={{ color: "white" }}>{web.activityTime.Saturday}</span></p>
                  <p className='home-p-time'> חגים:<span style={{ color: "white" }}>{web.activityTime.holidays}</span></p>
                </div>
              </div>
            </div>

          </>



          {/* ------------------------------------------ */}






        </div>
      ) : (
        <>
          <GetWebData />
        </>

      )}
    </div>
  );
}

export default Home;