import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../css/cart1.css';
import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { minusOrder1 } from '../redux/store/order';
import { plusOrder1 } from '../redux/store/order';
import { useNavigate, Link } from 'react-router-dom';
import PaymentInfo from './PaymentInfo';
import {setpayflag1} from '../redux/store/payflag';
import axios from 'axios';

function Cart(props) {
    const LowProfileId = JSON.parse(sessionStorage.getItem("LowProfileId"));

    const disppatch = useDispatch()
    const navigate = useNavigate();
    const web = useSelector((state) => state.setWeb.web.web);
    const order = useSelector((state) => state.setOrder.order);
    const [webOrder, setWebOrder] = useState([])
    const [webAlertFlag, setWebAlertFlag] = useState(false)
    const payflag = useSelector((state) => state.setpayflag.payflag);

    const handleModal = () => {
        setWebAlertFlag(false)
        disppatch(setpayflag1(false))

    }
    const dispatch = useDispatch()
    // const totalSum = webOrder.reduce((total, item) => total + ((item.onSalePrice ? item.onSalePrice : item.price) * item.count), 0);
    const [totalSum, setTotalSum] = useState(0)
    useEffect(() => {
        if (!web) return
        const totalSum = webOrder.reduce((total, item) => total + ((item.onSalePrice > 0 ? item.onSalePrice : item.price) * item.count), 0);
        const totalSumEilat = webOrder.reduce((total, item) => total + ((item.onSalePrice > 0 ? item.onSalePriceEilatPrice : item.eilatPrice) * item.count), 0);
        setTotalSum(web.eilat ? totalSumEilat : totalSum)
    }, [web, order, webOrder])

    const goToPay = () => {
        if (webOrder.length === 0) {
            alert("אין מוצרים בסל")
            return
        }
        disppatch(setpayflag1(true))
        setWebAlertFlag(true)
        
    }
    const minusOrder = (item, index) => {
        dispatch(minusOrder1({ index: index, item: item }))
    }

    const plusOrder = (item, index) => {

        dispatch(plusOrder1({ index: index, item: item }))
    }

    useEffect(() => {
        setWebOrder(order.filter(item => item.webID === web._id))
    }, [order, web])

    const cardPyment = async () => {
        const url = "https://image-imageserver.dvz6b5.easypanel.host/payment"
        const order = await axios.post(url)
        const url2 = "httpss://secure.cardcom.solutions/api/v11/LowProfile/GetLpResult"
        const data2 = {
            TerminalNumber: 153241,
            ApiName: "7CddWIaIBolWUahCkcLr",
            LowProfileId: order.data.LowProfileId
        }
        const order2 = await axios.post(url2, data2)
        console.log(order2.data);
        // window.location.href =`${order.data.Url}`
        console.log(order.data);
    }
    useEffect(() => {

        if (LowProfileId) {

            disppatch(setpayflag1(true))
            setWebAlertFlag(true)            
        }
        return

    }, [web])

    return (
        <div className='cart-container'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{web.name}-סל קניות</title>
                <link rel="icon" href={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} />
            </Helmet>
            {!payflag ?
            <>
            <h1 className='cart-h1'>סל קניות</h1>
            
            <div className="cart-products-container">
                {webOrder.map((product, index) => {

                    return (
                        <div className='cart-products' key={index}>
                            <hr />

                            <div className='cart-image-name-container'>

                                <img className='cart-image' src={`https://ryc-private-domain-image.emzsne.easypanel.host/${product.mainImage}`} alt="" />
                                <h4 className='cart-name'>{product.name}</h4>
                            </div>



                            {product.onSalePrice > 0 ?
                                // <div className='cart-price-count'>
                                <>
                                    <h3  className='cart-h4-price'>₪{web.eilat ? product.onSalePriceEilatPrice : product.onSalePrice * product.count}</h3>
                                    <del><p  className='cart-h4-sale-price'>₪{web.eilat ? product.eilatPrice : product.price * product.count}</p></del>
                                </>
                                // </div>

                                :
                                <h4 className='cart-h4-price'>₪{web.eilat? product.eilatPrice:product.price}</h4>
                            }

                            <div className='cart-count-container'>
                                <FaPlusCircle onClick={() => plusOrder(product, index)} size={20} style={{ paddingRight: "5px" }} />
                                <h4 className='cart-count'>{product.count}</h4>
                                <FaMinusCircle onClick={() => minusOrder(product, index)} size={20} style={{ paddingLeft: "5px" }} />
                            </div>

                            <hr />
                        </div>
                    )
                })}
                <div className='cart-total-price-container'>
                    <div className='cart-total-price'>
                        <h3>סכום ביניים</h3>
                        <h3>₪{totalSum}</h3>

                    </div>
                </div>
            </div>


            <div className='cart-pay'>

                <button onClick={goToPay} className='cart-pay-bt'>מעבר לתשלום</button>
            </div> 
            </>:
            <PaymentInfo order={webOrder} totalSum={totalSum} open={payflag} onClose={handleModal} />
            }
        </div>
    );
}

export default Cart;