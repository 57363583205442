import React, { useState, useEffect,} from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductSelectCard from './comp/ProductSelectCard';
import "../css/productSelects.css"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function ProductSelect(props) {
    const navigate = useNavigate();
    const [product, setProduct] = useState([]);
    const web = useSelector((state) => state.setWeb.web.web);
    const products = useSelector((state) => state.setProducts.products);
    const { category } = useParams();
    const filterProduct = products.filter((product) => product.category.includes(category));
    const newArr = filterProduct.map(item => ({
        ...item,
        price: item.onSalePrice>0? item.onSalePrice : item.price,
      }));
      
    const [filter, setFilter] = useState("");

    const filterPrice = (price) => {
        switch (price) {
            case "high": {
                const priceHighToLow = newArr.sort((a, b) => b.price - a.price);
                setProduct(priceHighToLow);
                setFilter("מחיר גבוהה לנמוך");
                return;
            }
            case "low":
                const priceLowToHigh = newArr.sort((a, b) => a.price - b.price);
                setProduct(priceLowToHigh);
                setFilter("מחיר נמוך לגבוהה");
                return
            case "new":
                const newToOld = newArr.reverse()
                setProduct(newToOld);
                setFilter("מהחדש לישן");
                return;
            default: {
                setProduct(filterProduct);
                setFilter("");
                return;
            }
        }
    }

    useEffect(() => {
        const finder = web.category.find((item) => item.name === category);
        if (finder === undefined) {
            navigate("/404");
        }
    }, [category]);

    useEffect(() => {
        setProduct(filterProduct);
    }, [products]);
  


    return (
        <div className="container1">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${web.name}-${category}`}</title>
                <link rel="icon" href={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} />
            </Helmet>

            <h1>{category}</h1>

            
            <div className='select'>
                <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                    <InputLabel id="demo-select-small-label">מיין לפי</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="filter"
                        value={"default"}
                        onChange={(e) => filterPrice(e.target.value)}
                    >
                        <MenuItem value="default">סינון</MenuItem>
                        <MenuItem value={"low"}>מחיר נמוך לגבוהה</MenuItem>
                        <MenuItem value={"high"}>מחיר גבוהה לנמוך</MenuItem>
                        <MenuItem value={"new"}>מהחדש לישן</MenuItem>
                        <MenuItem value={"normal"}> איפוס</MenuItem>

                    </Select>
                </FormControl>
                {filter === "default" || "normal" ?
                    <h4>{filter}</h4> : null

                }
            </div>
            <div className='product-select-container1'>
                
                {product.map((product, index) => (
                    <ProductSelectCard category={category} product={product} key={index} />
                ))}
            </div>
        </div>
    );
}

export default ProductSelect;
