import { createSlice } from "@reduxjs/toolkit";

const webSlice = createSlice({
    name: "web",
    initialState: {
        web: {}

    },
    reducers: {
        setweb: (state, action) => {
            state.web = action.payload
        },
        updateStatus: (state, action) => {
            state.web.web.status = action.payload.status
        },
        updatWeb: (state, action) => {
            state.web.web = action.payload
        },
       
    }
});

export const setweb1 = webSlice.actions.setweb;
export const updateStatus1 = webSlice.actions.updateStatus;
export const updatWeb1 = webSlice.actions.updatWeb;
export default webSlice.reducer; 