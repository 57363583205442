import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name : "admin",
    initialState : {
        admin :{},
    },
    reducers : {
        setadmin:(state, action)=>{
            state.admin = action.payload
        },

        updateAmdinImage:(state, action)=>{
            state.admin.images.push(action.payload)
        }
    }
});

export const setadmin1 = adminSlice.actions.setadmin;
export const updateAmdinImage1 = adminSlice.actions.updateAmdinImage;
export default adminSlice.reducer; 