import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import NavBar from './pages/NavBar';
import Footer from './pages/footer/Footer';
import styled from 'styled-components';
import Products from './pages/Products';
import ProductSelect from './pages/ProductSelect';
import Product from './pages/Product';
import About from './pages/About';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Loading from './Loading';
import ErrPage from './404-page/ErrPage';
import MainPage from './main page/MainPage';
import HomeAccessibility from './pages/UserAccessibility';
import Terms from './pages/Terms';
const AddProduct = lazy(() => import('./adminPages/AddProduct'));
const ControlPanel = lazy(() => import('./adminPages/ControlPanel'));
const AdminProducts = lazy(() => import('./adminPages/AdminProducts'));
const AdminOrder = lazy(() => import('./adminPages/AdminOrder'));
const Category = lazy(() => import('./adminPages/Category'));
const Color = lazy(() => import('./adminPages/Color'));
const AdminMain = lazy(() => import('./adminPages/AdminMain'));
const Login = lazy(() => import('./login/Login'));
const General = lazy(() => import('./adminPages/General'));
const AdminAlert = lazy(() => import('./adminPages/AdminAlert'));
const LogoBunner = lazy(() => import('./adminPages/LogoBunner'));
const Accessibility = lazy(() => import('./adminPages/Accessibility'));
const UpdateAccessibility = lazy(() => import('./adminPages/UpdateAccessibility'));
const Delivey = lazy(() => import('./adminPages/Delivey'));
const Paymnet = lazy(() => import('./adminPages/Payment'));
const RycTerms = lazy(() => import('./adminPages/RycTerms'));

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContainerWrap = styled.div`
  flex: 1;
`;
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't render anything
};
const Layout = ({ children }) => {
  return (
    <PageContainer>
      <ContainerWrap>
        <NavBar />
        {children}
      </ContainerWrap>
      <Footer />
    </PageContainer>
  );
};


function App() {

  return (
    <div >
      <Suspense fallback={<Loading />}>
        <Routes>

          {/* ---------------client----------------- */}
          <Route path="" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='products' element={<Products />} />
            <Route path='products/:category' element={<ProductSelect />} />
            <Route path='products/:category/:product' element={<Product />} />
            <Route path='about' element={<About />} />
            <Route path='contact' element={<Contact />} />
            <Route path='cart' element={<Cart />} />
            <Route path='HomeAccessibility' element={<HomeAccessibility />} />
            <Route path='terms' element={<Terms />} />
          </Route>
          {/* ---------------client----------------- */}


          {/* ---------------admin----------------- */}

          <Route path="/cp" element={<AdminMain />}>
            {/* <Route index element={<AdminMain />} /> */}
            <Route path='addproduct' element={<AddProduct />} />
            <Route path='controlPanel' element={<ControlPanel />} />
            <Route path='products' element={<AdminProducts />} />
            <Route path='order' element={<AdminOrder />} />
            <Route path='color' element={<Color />} />
            <Route path='general' element={<General />} />
            <Route path='alert' element={<AdminAlert />} />
            <Route path='bunner' element={<LogoBunner />} />
            <Route path='accessibility' element={<UpdateAccessibility />} />
            <Route path='category' element={<Category />} />
            <Route path='delivey' element={<Delivey />} />
            <Route path='payment' element={<Paymnet />} />
            <Route path='rycTerms' element={<RycTerms />} />
          </Route>
          {/* ---------------admin----------------- */}


          {/* ---------------login----------------- */}
          <Route path='login' element={<Login />} />
          {/* ---------------login----------------- */}

          <Route path="/*" element={<ErrPage />} />
          <Route path="/404" element={<ErrPage />} />

        </Routes>
      </Suspense>

      <React.Fragment>
        <ScrollToTop />
      </React.Fragment>
    </div>
  );
}
export default App;
