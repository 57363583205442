// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 300px;
    margin-top: 100px;
}

.contact-data {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.contact-h1{
    color: #FFA500;
}`, "",{"version":3,"sources":["webpack://./src/css/contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".contact-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 300px;\n    margin-top: 100px;\n}\n\n.contact-data {\n    align-items: center;\n    justify-content: center;\n    margin-top: 20px;\n}\n\n.contact-h1{\n    color: #FFA500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
