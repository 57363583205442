// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-accessibility {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.header {
    width: 100%;
}

.content {
    width: 80%;
    margin-top: 20px;
}

.accessibility {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/admin-css/accessibility.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".container-accessibility {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n}\n\n.header {\n    width: 100%;\n}\n\n.content {\n    width: 80%;\n    margin-top: 20px;\n}\n\n.accessibility {\n    font-weight: bold;\n    font-size: 20px;\n    margin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
