import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: "order",
    initialState: {
        order: [],
    },
    reducers: {
        setorder: (state, action) => {
            if (action.payload.order.selectedColor === "רגיל") {
                const findeOrder = state.order.find(item => item.name === action.payload.order.name)
                const index = state.order.findIndex(item => item.name === action.payload.order.name)
                if (findeOrder) {
                    const newOrder = [...state.order]
                    newOrder[index].count += 1
                    state.order = newOrder
                    return
                }

                state.order.push(action.payload.order)
                return
            } else {
                const newOrder = { ...action.payload.order, name: `${action.payload.order.name}-${action.payload.order.selectedColor}` }
                newOrder.name = `${action.payload.order.name}-${action.payload.order.selectedColor}`
                state.order.push(newOrder)
            }
        },


        deleteorder: (state, action) => {
            const filterOrder = state.order.filter(item => item.id !== action.payload.index.id)
            state.order = filterOrder
        },

        minusOrder: (state, action) => {
            const findeIndex = state.order.findIndex(item => item.id === action.payload.item.id)
            const newOrder = [...state.order]
            newOrder[findeIndex].count -= 1
            if (newOrder[findeIndex].count === 0) {
                newOrder.splice(findeIndex, 1)
            }
            state.order = newOrder
        },

        plusOrder: (state, action) => {
            const findeIndex = state.order.findIndex(item => item.id === action.payload.item.id)

            const newOrder = [...state.order]
            newOrder[findeIndex].count += 1
            state.order = newOrder
        },

        setNewOrder: (state, action) => {
            const findeIndex = state.order.findIndex(item => item._id === action.payload.item1._id)
            const newOrder = [...state.order]
            newOrder[findeIndex].price = action.payload.item1.price
            state.order = newOrder
        },

        clearOrderByWebId: (state, action) => {
            const newOrder = [...state.order]
            const filterOrder = newOrder.filter(item => item.webID !== action.payload.id)
            console.log(filterOrder);
            state.order = filterOrder

        }


    }
});

export const setorder1 = orderSlice.actions.setorder;
export const deleteorder1 = orderSlice.actions.deleteorder;
export const minusOrder1 = orderSlice.actions.minusOrder;
export const plusOrder1 = orderSlice.actions.plusOrder;
export const setNewOrder1 = orderSlice.actions.setNewOrder;
export const clearOrderByWebId1 = orderSlice.actions.clearOrderByWebId;
export default orderSlice.reducer; 