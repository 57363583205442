import React from 'react';
import './err.css';

function ErrPage(props) {
    return (
        <div className='err-container'>
            <img className='err-img' src={`https://ryc-private-domain-image.emzsne.easypanel.host/23581957_2104.i301.009.F.m004.c9.404 error isometric background.jpg`} alt="" />
            <h1 className='err-h1'>מצטערים, העמוד שחיפשת לא קיים</h1>
        </div>
    );
}
export default ErrPage;