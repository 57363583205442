import { createSlice } from "@reduxjs/toolkit";

const aaaSlice = createSlice({
    name : "aaa",
    initialState : {
        aaa :"123",
        bbb : "456"
    },
    reducers : {
        setaaa:(state, action)=>{
            state.aaa = action.payload
        },
    }
});

export const setaaa1 = aaaSlice.actions.setaaa;
export default aaaSlice.reducer; 