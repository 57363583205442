import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name : "cart",
    initialState : {
        cart :false,
    },
    reducers : {
        setcart:(state, action)=>{
            state.cart = !state.cart
        },
    }
});

export const setcart1 = cartSlice.actions.setcart;
export default cartSlice.reducer; 