import React from 'react';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function Loading(props) {
    const arr = [1, 2]

    

    

    


    return (
        <div style={styles.container}>
            <div style={styles.navbar1}>
                <div style={styles.movingGradient}></div>
            </div>



            <div style={styles.content}>
                {/* Your component content goes here */}
                <h1><Skeleton /> </h1>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", marginTop: "20px" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>
                            </div>
                        )
                    })}

                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>

                            </div>
                        )
                    })}

                </div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>

                    {arr.map((item, index) => {
                        return (
                            <div key={index} style={styles.card}>

                            </div>
                        )
                    })}

                </div>

            </div>




            <div style={styles.footer}>
                {/* Footer content goes here */}
            </div>
        </div>
    );
}
const styles = {
    container: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    navbar1: {
        height: '70px',
        position: 'relative',
        overflow: 'hidden',
    },
    movingGradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',
    },
    '@keyframes moveGradient': {
        from: { backgroundPosition: '0% 50%' },
        to: { backgroundPosition: '100% 50%' },
    },
    content: {
        width: '50%',
        margin: '0 auto',  // Center the content horizontally
        marginTop: '30px',

    },
    footer: {
        height: '170px',
        position: 'relative',
        overflow: 'hidden',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',
        marginTop: '0%',
    },

    card: {
        marginTop: '50px',
        width: '200px',
        height: '200px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '0 auto',
        marginTop: '50px',
        background: 'linear-gradient(to right, #fff, #ccc, #fff)',
        animation: 'moveGradient 5s linear infinite',
        
    },
};
export default Loading;