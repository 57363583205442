import { configureStore } from "@reduxjs/toolkit";
import {
   persistStore,
   persistReducer,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER,
} from 'redux-persist'
import storage from "redux-persist/lib/storage";
import products from "./store/products";
import aaaSlice from "./store/aaa";
import web from "./store/web";
import order from "./store/order";
import cart from "./store/cart";
import token from "./ad-srote/token";
import admin from "./ad-srote/admin";
import adminAAA from "./ad-srote/adminOrder";
import payflag from "./store/payflag";
import userdata from "./store/userdata";
import cardid from "./store/cardid";
import paymentorder from "./store/paymentorder";

const persistConfig = {
   key: 'root',
   storage,
   whitelist: ['order','payflag','cardid','paymentorder'],

};

const persistedReducer = persistReducer(persistConfig, aaaSlice);
const persistedReducer1 = persistReducer(persistConfig, products);
const persistedReducer2 = persistReducer(persistConfig, web);
const persistedReducer3 = persistReducer(persistConfig, order);
const persistedReducer4 = persistReducer(persistConfig, cart);
const persistedReducer5 = persistReducer(persistConfig, token);
const persistedReducer6 = persistReducer(persistConfig, admin);
const persistedReducer7 = persistReducer(persistConfig, adminAAA);
const persistedReducer8 = persistReducer(persistConfig, cardid);
const persistedReducer9 = persistReducer(persistConfig, payflag);
const persistedReducer10 = persistReducer(persistConfig, userdata);
const persistedReducer11 = persistReducer(persistConfig, paymentorder);


const store = configureStore({
   reducer: {
      setAaa: persistedReducer,
      setProducts: persistedReducer1,
      setWeb: persistedReducer2,
      setOrder: persistedReducer3,
      setCart: persistedReducer4,
      setToken: persistedReducer5,
      setAdmin: persistedReducer6,
      setAdminOrder: persistedReducer7,
      setcardid: persistedReducer8,
      setpayflag: persistedReducer9,
      setuserdata: persistedReducer10,
      setpaymentorder: persistedReducer11,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
         },
      }),
});

const persistor = persistStore(store);

export { store, persistor };