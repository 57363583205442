// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.err-container {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    height: 80vh; /* Set the height of the container to 100% of the viewport height */
}

.err-img{
    width: 500px;
    height: 500px;
}

@media screen and (max-width: 850px){
    .err-container{
        flex-direction: column;
    }

    .err-img{
        width: 300px;
        height: 300px;
    }

    .err-h1{
        font-size: 20px;
        margin-top: 10px;
    }

}`, "",{"version":3,"sources":["webpack://./src/404-page/err.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,yBAAyB;IAClD,mBAAmB,EAAE,uBAAuB;IAC5C,YAAY,EAAE,mEAAmE;AACrF;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;AAEJ","sourcesContent":[".err-container {\n    display: flex;\n    justify-content: center; /* Horizontal centering */\n    align-items: center; /* Vertical centering */\n    height: 80vh; /* Set the height of the container to 100% of the viewport height */\n}\n\n.err-img{\n    width: 500px;\n    height: 500px;\n}\n\n@media screen and (max-width: 850px){\n    .err-container{\n        flex-direction: column;\n    }\n\n    .err-img{\n        width: 300px;\n        height: 300px;\n    }\n\n    .err-h1{\n        font-size: 20px;\n        margin-top: 10px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
