import React from 'react';
import "./footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWaze } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function Footer(props) {

    const web = useSelector((state) => state.setWeb.web.web);


    return (
        <div>

            {web ?
                <div style={{ backgroundColor: web.color }} className='footer' >
                    <div className="sb-footer section-padding">
                        <div className="sb-footer-Links">
                            {web.activityTime ?
                                <div className="sb-footer-links-div">
                                    <h4> שעות פעילות</h4>

                                    <p>  ימים א’ עד ד’:  {web.activityTime.day}</p>


                                    <p>יום ה’:  {web.activityTime.Thursday}</p>


                                    <p>יום ו’:  {web.activityTime.Friday}</p>
                                    <p>יום שבת:  {web.activityTime.Saturday}</p>
                                    <p> חגים:  {web.activityTime.holidays}</p>

                                </div> : null
                            }

                            <div className="sb-footer-links-div">
                                <Link to={`/products`}>
                                    <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}>הזמנה און ליין</h4>
                                </Link>
                                <Link to={`/about`}>
                                    <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}>אודותנו</h4>
                                </Link>
                                <Link to={`/terms`}>
                                <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}>תקנון </h4>
                                </Link>
                                <Link to={`/HomeAccessibility`}>
                                <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}> הצהרת נגישות</h4>
                                </Link>

                            </div>
                            <div className="sb-footer-links-div">
                                <Link to={`/cart`}>
                                    <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}>סל קניות</h4>
                                </Link>
                                <Link to={`/contact`}>
                                    <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "white" }}> צרו קשר</h4>
                                </Link>
                                <Link to={`tel:${web.contact.phone}`}>
                                    <h4 style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "0.9rem", color: "#FFA500" }}>   להזמנות: {web.contact.phone}</h4>
                                </Link>

                            </div>
                            <div className="sb-footer-links-div">
                                <img style={{width:"180px",height:"150px"}} src={`https://ryc-private-domain-image.emzsne.easypanel.host/${web.logo}`} alt="" />

                            </div>
                            <div className="sb-footer-links-div">
                                <div className="socialmedia">
                                    {web.instagram ? <FaInstagram size={25} onClick={() => window.location.href = ` ${web.instagram}`} color='white' style={{ marginRight: web.facebook ? "35px" : "50px", marginLeft: "20px" }} /> : null}
                                    {web.facebook ? <FaFacebook size={25} onClick={() => window.location.href = ` ${web.facebook}`} color='white' style={{ marginLeft: "20px", marginRight: web.instagram ? null : "35px", marginTop: web.instagram ? null : "120%" }} /> : null}
                                    {web.contact.address ? <FaWaze onClick={() => window.location.href = `https://waze.com/ul?q=some%20${web.contact.address}`} size={25} color='white' /> : null}

                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="sb-footer-below">
                            <div className="sb-footer-copyright">
                                <p>
                                    כל הזכויות שמורות  © ryc {new Date().getFullYear()}
                                </p>
                            </div>
                            <div className="sb-footer-below-links">
                               
                                <a href="https://ryc.co.il/"><div><p>ryc.co.il</p></div></a> &nbsp; &nbsp;
                            </div>
                        </div>

                    </div>
                </div>

                : null
            }

        </div>
    );
}

export default Footer;