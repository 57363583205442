import { createSlice } from "@reduxjs/toolkit";

const adminOrderSlice = createSlice({
    name: "order",
    initialState: {
        adminOrder: [],
    },
    reducers: {
        setorder2: (state, action) => {
            state.adminOrder = action.payload.order;
        },

        updateOrder: (state, action) => {
            const findIndex = state.adminOrder.findIndex((order) => order._id === action.payload.order._id);
            state.adminOrder[findIndex] = action.payload.order;
        },


    },
});

export const setorder12 = adminOrderSlice.actions.setorder2;
export const updateOrder = adminOrderSlice.actions.updateOrder;
export default adminOrderSlice.reducer; 