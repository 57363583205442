import { createSlice } from "@reduxjs/toolkit";

const userdataSlice = createSlice({
    name : "userdata",
    initialState : {
        userdata :[],
    },
    reducers : {
        setuserdata:(state, action)=>{
           
            state.userdata = action.payload
        },
    }
});

export const setuserdata1 = userdataSlice.actions.setuserdata;
export default userdataSlice.reducer; 