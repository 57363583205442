import { createSlice } from "@reduxjs/toolkit";

const paymentorderSlice = createSlice({
    name : "paymentorder",
    initialState : {
        paymentorder :[],
    },
    reducers : {
        setpaymentorder:(state, action)=>{
            console.log(action.payload);
            state.paymentorder = action.payload
        },
    }
});

export const setpaymentorder1 = paymentorderSlice.actions.setpaymentorder;
export default paymentorderSlice.reducer; 