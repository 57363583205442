import { createSlice } from "@reduxjs/toolkit";

const cardidSlice = createSlice({
    name : "cardid",
    initialState : {
        cardid :"false",
    },
    reducers : {
        setcardid:(state, action)=>{
            state.cardid = action.payload
        },
    }
});

export const setcardid1 = cardidSlice.actions.setcardid;
export default cardidSlice.reducer; 